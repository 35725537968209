import type {
    UserProfile,
    SetPassword,
    LoginResponse,
    Login,
    UserSignUpRequest,
    ForgotPassword,
    VerifyResetToken,
    ResetPassword,
    DefaultAddressType,
    Address,
    StripeShippingObject,
    PaginatedPayload,
    CustomPagination,
    UserProfileUpdateResponse,
    Membership,
    MembershipMember,
    MembershipPending,
    MembershipInviteRequest,
    SignUpStep1Response,
    SignUpStep2Request,
    SignUpStep2Response,
    ErrorTags
} from '~/types';
import {
    useApiUtils,
    useStripeUtils,
    useCustomFetch,
    useUserUtils,
    useCustomAuth
} from '~/composables';
import { UserModel } from '~/models';
import { omit as lo_omit } from 'lodash-es';
import { isError as lo_isError } from 'lodash-es';
import { find as lo_find } from 'lodash-es';
import type { NitroFetchOptions } from 'nitropack';
import type { FetchError } from 'ofetch';
import type { NuxtError } from '#app';


export function useUser() {
    const {
        isLoggedIn,
        setSession,
        getSession,
        sessionObj: userObj,
        signIn: authSignIn
    } = useCustomAuth();
    const apiBasePath = `/v1/user`;
    const apiBaseAddressesPath = `/v1/addresses`;
    const apiBaseOrgPath = `/v1/memberships`;
    const $_fetch = useCustomFetch();

    const useApiUtilsObj = useApiUtils();
    const useStripeUtilsObj = useStripeUtils();
    const useUserUtilsObj = useUserUtils();

    const accountCreditBalance = computed<number>(() => userObj.value?.accountCreditBalance ?? 0);

    const defaultBillingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultBillingAddressUid ?? null);
    const defaultShippingAddressId = computed<Nullable<string>>(() => userObj.value?.defaultShippingAddressUid ?? null);

    const hasMemberships = computed<boolean>(() => Boolean(userObj.value?.availableMembershipsSiteData.length));
    const selectedMembershipUid = computed<string>(() => userObj.value?.selectedMembershipUid ?? '');
    const currentMembershipObj = computed<Nullable<Membership>>(() => {
        if (!userObj.value) {
            return null;
        }

        const membershipUid = selectedMembershipUid.value;

        if (membershipUid) {
            return lo_find(userObj.value?.availableMembershipsSiteData, { uid: membershipUid }) ?? null;
        }
        
        // Reset the role
        return null;
    });

    const isReseller = computed<boolean>(() => currentMembershipObj.value?.customer.isRetail ?? false);

    async function fetchUser(force = false): Promise<boolean | Error> {
        try {
            await getSession(force);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signIn(payload: Login): Promise<LoginResponse | Error> {
        try {
            const response = await authSignIn(payload);

            if (!lo_isError(response) && response?.user?.uid) {
                useUserUtilsObj.setUserIdCookie(response.user.uid);
            }

            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signUpStep1(signUpObj: Partial<UserSignUpRequest>): Promise<SignUpStep1Response | ErrorTags | Error> {
        const url = `${apiBasePath}/sign-up/`;
        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: signUpObj
        };

        try {
            return await $_fetch<SignUpStep1Response>(url, config);
        } catch (err) {
            const errObj = err as FetchError;
            const errorTag = errObj?.data?.[0];

            if (errorTag) {
                return {
                    errors: [errorTag]
                };
            }

            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function signUpStep2(signUpObj: SignUpStep2Request): Promise<SignUpStep2Response | Error> {
        const url = `${apiBasePath}/token-set-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: signUpObj
        };

        try {
            return await $_fetch<SignUpStep2Response>(url, config);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getItem(uid: string): Promise<UserModel | Error> {
        const url = `${apiBasePath}/${uid}/`;

        try {
            const response = await $_fetch<UserProfile>(url);
            return UserModel.toPlainObject(response);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function updateUser(userProfile: Partial<UserProfile>): Promise<UserModel | Error | void> {
        const uid = userProfile.uid ?? userObj.value?.uid;

        if (!uid) {
            // shouldn't normally get here; do some sort of "soft logout" and some clean up via $auth
            return;
        }

        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: userProfile
        };

        const url = `${apiBasePath}/${uid}/`;

        try {
            const response = await $_fetch<UserProfileUpdateResponse>(url, config);
            const userObj = response.user;

            // TODO: check if user is valid and response is not an error

            setSession(userObj);
            return UserModel.toPlainObject(userObj);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setPassword(setPasswordObj: SetPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/set-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: setPasswordObj
        };

        try {
            const response = await $_fetch<UserProfile>(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function forgotPassword(forgotPasswordObj: ForgotPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/forgot-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: forgotPasswordObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            const errObj = err as NuxtError | Error;

            if ('statusCode' in errObj && errObj.statusCode === 404) {
                return false;
            }

            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function resetPassword(resetPasswordObj: ResetPassword): Promise<Error | boolean> {
        const url = `${apiBasePath}/reset-password/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: resetPasswordObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function verifyResetToken(verifyResetTokenObj: VerifyResetToken): Promise<Error | boolean> {
        const url = `${apiBasePath}/validate-password-token/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: verifyResetTokenObj
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setDefaultAddress(
        uid: string | null,
        type: DefaultAddressType = 'shipping'
    ): Promise<Error | boolean> {
        const url = `${apiBasePath}/set-default-${type}-address/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH',
            body: { addressUid: uid }
        };

        try {
            await $_fetch(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getDefaultAddress(type: DefaultAddressType = 'shipping'): Promise<Address | Error | void> {
        const uid = defaultShippingAddressId.value;

        if (!uid) {
            return;
        }

        const url = `${apiBaseAddressesPath}/${uid}/`;

        try {
            const response = await $_fetch<Address>(url);
            return response;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getDefaultAddressForStripe(): Promise<StripeShippingObject | Error | void> {
        try {
            const response = await getDefaultAddress();

            if (response && !lo_isError(response)) {
                return useStripeUtilsObj.toStripeAddress(response);
            }
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getMemberships(uid: string): Promise<PaginatedPayload<Membership> | Error | void> {
        // TODO: handle pagination params
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: { expand: 'user', page_size: 100 }
        };

        uid = uid ?? selectedMembershipUid.value;

        if (!uid) {
            return;
        }

        const url = `${apiBaseOrgPath}/${uid}/`;
        let payload: Membership[];
        let paginatedPayload: PaginatedPayload<Membership>;

        try {
            const response = await $_fetch<CustomPagination<Membership>>(url, config);
            payload = response.results;

            paginatedPayload = {
                pagination: lo_omit(response, 'results'),
                data: payload
            };

            return paginatedPayload;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getMembershipMembers(uid: string): Promise<PaginatedPayload<MembershipMember> | Error | void> {
        // TODO: handle pagination params
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: { expand: ['user'], page_size: 100 }
        };

        uid = uid ?? selectedMembershipUid.value;

        if (!uid) {
            return;
        }

        const url = `${apiBaseOrgPath}/${uid}/members/`;
        let payload: MembershipMember[];
        let paginatedPayload: PaginatedPayload<MembershipMember>;

        try {
            const response = await $_fetch<CustomPagination<MembershipMember>>(url, config);
            payload = response.results;

            paginatedPayload = {
                pagination: lo_omit(response, 'results'),
                data: payload
            };

            return paginatedPayload;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function getMembershipPending(uid: string): Promise<PaginatedPayload<MembershipPending> | Error | void> {
        // TODO: handle pagination params
        const config: NitroFetchOptions<string> = {
            method: 'GET',
            query: { expand: ['user'], page_size: 100 }
        };
        
        uid = uid ?? selectedMembershipUid.value;

        if (!uid) {
            return;
        }

        const url = `${apiBaseOrgPath}/${uid}/pending-invitations/`;
        let payload: MembershipPending[];
        let paginatedPayload: PaginatedPayload<MembershipPending>;

        try {
            const response = await $_fetch<CustomPagination<MembershipPending>>(url, config);
            payload = response.results;

            paginatedPayload = {
                pagination: lo_omit(response, 'results'),
                data: payload
            };

            return paginatedPayload;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function deleteMembershipMember(uid: string): Promise<boolean | Error> {
        const url = `${apiBaseOrgPath}/${uid}/`;
        const config: NitroFetchOptions<string> = {
            method: 'DELETE'
        };

        try {
            const response = await $_fetch.raw<unknown>(url, config);

            if (!response || response?.status > 399) {
                throw new Error('Error deleting item.');
            }

            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function setMembershipUid(membershipUid?: string): Promise<void | Error | undefined> {
        if (!membershipUid) {
            return;
        }

        const url = `${apiBaseOrgPath}/${membershipUid}/set-selected-membership/`;
        const config: NitroFetchOptions<string> = {
            method: 'PATCH'
        };

        try {
            await $_fetch<UserProfile>(url, config);
            return;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function sendMembershipInvites(membershipUid: string, rawEmails: string): Promise<boolean | Error> {
        const url = `${apiBaseOrgPath}/${membershipUid}/invites/`;

        const payload: MembershipInviteRequest = {
            rawEmails
        };

        const config: NitroFetchOptions<string> = {
            method: 'POST',
            body: payload
        };

        try {
            await $_fetch<UserProfile>(url, config);
            return true;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    function getOrgMemberPermission(permission: string): Undefinable<boolean> {
        if (currentMembershipObj.value && permission in currentMembershipObj.value.permissions) {
            return currentMembershipObj.value.permissions[permission];
        }
    }

    function isOrgMemberAllowed(permission?: Nilish<string>): boolean {
        if (!permission) {
            return true;
        }

        return getOrgMemberPermission(permission) ?? false;
    }

    return {
        userObj,
        accountCreditBalance,
        fetchUser,
        isLoggedIn,
        hasMemberships,
        selectedMembershipUid,
        isReseller,
        signIn,
        signUpStep1,
        signUpStep2,
        getItem,
        updateUser,
        setPassword,
        forgotPassword,
        resetPassword,
        verifyResetToken,
        setDefaultAddress,
        getDefaultAddress,
        getDefaultAddressForStripe,
        defaultBillingAddressId,
        defaultShippingAddressId,
        getMemberships,
        currentMembershipObj,
        getMembershipMembers,
        getMembershipPending,
        deleteMembershipMember,
        setMembershipUid,
        sendMembershipInvites,
        getOrgMemberPermission,
        isOrgMemberAllowed
    };
}
